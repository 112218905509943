import { conditionalSpread, isExternalURL, safeREL } from 'clyne-core';
import { toProps } from 'clyne-core/props';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const To = props => {
    const {
        url,
        style,
        title,
        target,
        onClick,
        children,
        className,
        ariaLabel,
        onMouseEnter,
        onMouseLeave,
        onPointerEnter,
        onPointerLeave,
    } = props;

    const sharedProps = {
        style,
        title,
        target,
        onClick,
        className,
        onMouseEnter,
        onMouseLeave,
        onPointerEnter,
        onPointerLeave,
        'aria-label': ariaLabel,
        ...conditionalSpread({
            rel: safeREL,
        }, target !== '_self'),
    };

    return isExternalURL(url) ? (
        <a
            href={url}
            {...sharedProps}
        >
            {children}
        </a>
    ) : (
        <NavLink
            {...sharedProps}
            to={url || '/'}
        >
            {children}
        </NavLink>
    );
};

To.defaultProps = {
    url: '/',
    target: toProps.target[0],
};

To.propTypes = {
    url: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
    target: PropTypes.oneOf(toProps.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onPointerEnter: PropTypes.func,
    onPointerLeave: PropTypes.func,
};

export default To;
