import React, { memo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { rem } from 'clyne-core';

import './index.scss';

const Icon = memo(props => {
    const {
        size,
        name,
        type,
        color,
        style,
        className,
    } = props;

    const [loaded, setLoaded] = useState(false);

    return (
        <div
            style={{
                color,
                width: rem(size),
                height: rem(size),
                ...(style || {}),
            }}
            className={classNames(
                'icon-c-holder',
                `t-${type}`,
                `n-${name}`,
                className,
            )}
        >
            <SVG
                width={size}
                height={size}
                onLoad={() => setLoaded(false)}
                src={`/assets/icons/${type}/${name}.svg`}
            />
            {!loaded && (
                <img
                    alt='icon'
                    width={size}
                    height={size}
                    src={`/assets/icons/${type}/${name}.svg`}
                />
            )}
        </div>
    );
});

Icon.defaultProps = {
    size: 24,
    type: 'line',
};

Icon.propTypes = {
    size: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOf([
        'line',
        'solid',
        'filled',
    ]),
    color: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
};

export default Icon;
